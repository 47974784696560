<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} categoría de ingreso`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col 
          class="pb-0"       
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Título:</v-subheader>
        </v-col>
        <v-col class="pb-0">
          <v-text-field
            v-model="incomeCategory.dato"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de categorias de ingreso"
      divider-position="bottom"
    />
    <kn-list-with-actions
      :activeItems="activeIncomeCategories"
      @updateInline="updateInline"
      @deleteInline="deleteInline"
      @cancelInline="clearInline"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue'),
    KnListWithActions: () => import('./KnListWithActions.vue')
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      incomeCategory: {
        id: 'NA', // No asignado
        dato: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      incomeCategories: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Agregar'
    },
    activeIncomeCategories() {
      return this.incomeCategories.filter(category => category.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.incomeCategory)
    }
  },
  async created() {
    this.incomeCategory.id_autor = this.userData.id
    this.incomeCategory.id_institucion_educativa = this.institutionId
    this.incomeCategories = await this.fetchIncomeCategoryByEI(this.institutionId)

  },
  methods: {
    async fetchIncomeCategoryByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/categoria-ingreso-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener categorias de ingreso por institucion educativa', error);
      }
    },
    async createIncomeCategory() {
      try {
        const response = await api.post('/administracion/crear-categoria-ingreso', {
          nombre_categoria_ingreso: this.incomeCategory.dato,
          id_autor: this.incomeCategory.id_autor,
          id_institucion_educativa: this.incomeCategory.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la categoria de ingreso.')
        }
      } catch (error) {
        console.error('Error al intentar crear categoria de ingreso', error)
        this.errors.push('Error al intentar crear categoria de ingreso')
      }
    },
    async updateIncomeCategory() {
      try {
        const response = await api.patch('/administracion/update-categoria-ingreso', {
          id: this.incomeCategory.id,
          categoria_ingreso: this.incomeCategory.dato,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la categoria de ingreso')
        }
      } catch (error) {
        console.error('Error al intentar actualizar categoria de ingreso', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deleteIncomeCategory(typeId) {
      try {
        const response = await api.post('/administracion/delete-categoria-ingreso', {
          id_categoria_ingreso: typeId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la categoria de ingreso')
        }
      } catch (error) {
        console.error('Error al intentar eliminar categoria de ingreso', error);
      }
    },
    async save() {
      await this.createIncomeCategory()
      this.incomeCategories = await this.fetchIncomeCategoryByEI(this.institutionId)
      this.clear()
    },
    clear() {
      this.incomeCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.incomeCategory = {
        id: 'NA', // No asignado
        dato: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    async updateInline(item) {
      // console.log('updateInline item', item);
      this.incomeCategory = {...item}
      await this.updateIncomeCategory()
      this.incomeCategories = await this.fetchIncomeCategoryByEI(this.institutionId)
      this.clearInline()
    },
    async deleteInline(itemId) {
      await this.deleteIncomeCategory(itemId)
      this.incomeCategories = await this.fetchIncomeCategoryByEI(this.institutionId)
      this.clearInline()
    }
  },
}
</script>

<style>

</style>